import React, { useState, useContext, useEffect } from "react";
import {NavLink, useHistory, useParams} from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const BranchDelive = (slug) => {
  const { t } = useTranslation();
  const history = useHistory();
  const propertySlug = useParams();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);
  let {
    //common
    branchShipping,
    getBranchShipping,
    setBranchShipping
  } = useContext(RestaurantContext);
  //useEffect- to get data on render
  const [branchSlug,setBranchSlug]=useState("");
  useEffect(() => {
    //call- unauthenticated
    getBranchShipping(propertySlug.slug);
    setBranchSlug(propertySlug.slug);
  }, []);


  const [newShipping,setNewShipping]=useState({
    distance: "",
    price: "",
    shipping_fees: "",
    edit: false,
    editId: null,
    uploading: false,
    branch_slug:propertySlug.slug
  })

  //set name, phn no, address hook
  const handleSetNewShipping = (e) => {
    setNewShipping({ ...newShipping, [e.target.name]: e.target.value });
  };

  const handleSaveNewBranchShipping = (e) => {
    e.preventDefault();
    setNewShipping({
      ...newShipping,
      uploading: true
    });
    // setLoading(true);
    const branchUrl = BASE_URL + "/settings/get-branch-shipping-edit";
    let formData = new FormData();
    formData.append("distance",newShipping.distance)
    formData.append("price",newShipping.price)
    formData.append("shipping_fees",newShipping.shipping_fees)
    formData.append("editId",newShipping.editId|"")
    formData.append("branch_slug",branchSlug)
    return axios
        .post(branchUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setNewShipping({
            distance: "",
            price: "",
            shipping_fees: "",
            edit: false,
            editId: null,
            uploading: false,
            branch_slug:branchSlug
          });
          setBranchShipping(res.data)
          // setLoading(false);
          toast.success(`${_t(t("Branch Shipping has been updated"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        })
        .catch(() => {
          setLoading(false);
          setNewShipping({
            ...newShipping,
            uploading: false,
          });
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
  }

  const handleDestoryBranchShipping = (id) => {
    console.log(id);
    // setLoading(true);
    const url = BASE_URL + "/settings/get-branch-shipping-destory/"+id;
     axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setBranchShipping(res.data);
          // setLoading(false);
        });
  }

  return (
    <>
      <Helmet>
        <title>{_t(t("Branches"))}</title>
      </Helmet>

      {/* Add modal */}
      <div className="modal fade" id="addBranch" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newShipping.edit
                      ? _t(t("Add new branch Shipping range"))
                      : _t(t("Update branch Shipping range"))}
                </h5>
              </div>
              <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {newShipping.uploading === false ? (
                  <div key="fragment-branch-1">
                    <form
                        onSubmit={
                          handleSaveNewBranchShipping
                        }
                        action=""
                    >
                      <div>
                        <label htmlFor="distance" className="form-label">
                          {_t(t("Distance"))}

                          <small style={{fontSize:"12px"}}>  (km)</small>
                          <small className="text-primary">*</small>
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="distance"
                            name="distance"
                            placeholder="Delivery range of 10 km e.g. 10"
                            value={newShipping.distance || ""}
                            required
                            onChange={handleSetNewShipping}
                        />
                      </div>

                      <div className="mt-3">
                        <label htmlFor="price" className="form-label">
                          {_t(t("起送价格"))}
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="price"
                            name="price"
                            placeholder="起送价格 "
                            value={newShipping.price || ""}
                            onChange={handleSetNewShipping}
                        />
                      </div>

                      <div className="mt-3">
                        <label htmlFor="shipping_fees" className="form-label">
                          {_t(t("配送费"))}
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="shipping_fees"
                            name="shipping_fees"
                            placeholder="配送费"
                            value={newShipping.shipping_fees || ""}
                            onChange={handleSetNewShipping}
                        />
                      </div>



                      <div className="mt-4">
                        <div className="row">
                          <div className="col-6">
                            <button
                                type="submit"
                                className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                            >
                              {!newShipping.edit ? _t(t("Save")) : _t(t("Update"))}
                            </button>
                          </div>
                          <div className="col-6">
                            <button
                                type="button"
                                className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                                data-dismiss="modal"
                            >
                              {_t(t("Close"))}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
              ) : (
                  <div key="fragment2">
                    <div className="text-center text-primary font-weight-bold text-uppercase">
                      {_t(t("Please wait"))}
                    </div>
                    {modalLoading(3)}
                    <div className="mt-4">
                      <div className="row">
                        <div className="col-6">
                          <button
                              type="button"
                              className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                          >
                            {!newShipping.edit ? _t(t("Save")) : _t(t("Update"))}
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                              type="button"
                              className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                              data-dismiss="modal"
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                 {_t(t("Branch Delives List"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search group */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">


                                </div>
                              </div>

                              {/* Add group modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block"
                                  data-toggle="modal"
                                  data-target="#addBranch"
                                  onClick={() => {

                                  }}
                                >
                                  {_t(t("add new"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Shipping range"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Starting from the price"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Shipping fees"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                            {branchShipping.length==0?(
                                <tr className="align-middle">
                                  <td
                                      scope="row"
                                      colSpan="6"
                                      className="xsm-text align-middle text-center"
                                  >
                                    {_t(t("No data available"))}
                                  </td>
                                </tr>
                            ):(
                                branchShipping.map((item,itemIndex)=>{
                                  return (<tr
                                      className="align-middle"
                                      // key={}
                                  >
                                    <th
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {item.id}
                                    </th>

                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      {item.distance} Km
                                    </td>

                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      {item.price}
                                    </td>

                                    <td className="xsm-text align-middle text-center">
                                      {item.shipping_fees}
                                    </td>

                                    <td className="xsm-text text-capitalize align-middle text-center">
                                      <div className="dropdown">
                                        <button
                                            className="btn t-bg-clear t-text-dark--light-40"
                                            type="button"
                                            data-toggle="dropdown"
                                        >
                                          <i className="fa fa-ellipsis-h"></i>
                                        </button>
                                        <div className="dropdown-menu">
                                          <button
                                              className="dropdown-item sm-text text-capitalize"
                                              onClick={() =>{
                                                  setNewShipping({
                                                    ...newShipping,
                                                    distance: item.distance,
                                                    price: item.price,
                                                    shipping_fees: item.shipping_fees,
                                                    editId: item.id,
                                                    edit: true,
                                                    uploading: false,
                                                  })
                                                }
                                              }
                                              data-toggle="modal"
                                              data-target="#addBranch"
                                          >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                            {_t(t("Edit"))}
                                          </button>
                                          <button
                                              className="dropdown-item sm-text text-capitalize"
                                              onClick={() => {
                                                handleDestoryBranchShipping(item.id)
                                              }}
                                          >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                            {_t(t("Delete"))}
                                          </button>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>)
                                })
                            )}


                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}

                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default BranchDelive;
