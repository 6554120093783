import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
// import "./App.css";
import App from "./App";
import "./i18next";

//importing context provider here
import { UserProvider } from "./contexts/User";
import { SettingsProvider } from "./contexts/Settings";
import { RestaurantProvider } from "./contexts/Restaurant";
import { FoodProvider } from "./contexts/Food";
ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <SettingsProvider>
        <RestaurantProvider>
          <FoodProvider>
            <App />
          </FoodProvider>
        </RestaurantProvider>
      </SettingsProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('khadyo')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
