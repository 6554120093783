import React, { useState, useContext, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import {Helmet} from "react-helmet";
import {_t, getCookie} from "../../../../../functions/Functions";
import {useTranslation} from "react-i18next";
import Promotions from "../website/Promotions";
import {BASE_URL} from "../../../../../BaseUrl";
import axios from "axios";
import {toast} from "react-toastify";

const ExcelItem=()=>{
    let [newFile, setNewFile] = useState(null);
    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append("excel", newFile.target.files[0]);
        const url = BASE_URL + "/settings/new-food-excel";
        return axios
            .post(url, formData, {
                headers: { Authorization: `Bearer ${getCookie()}` },
                responseType: 'blob'
            }).then((res) => {
                if(isJSON(res)){
                    setNewFile(null)
                    toast.success(`${_t(t("Food item has been added"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                }else{
                    const blob = new Blob([res.data])
                    const fileName = '导入失败数据.xlsx'
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a')
                        elink.download = fileName
                        elink.style.display = 'none'
                        elink.href = URL.createObjectURL(blob)
                        document.body.appendChild(elink)
                        elink.click()
                        URL.revokeObjectURL(elink.href) // 释放URL 对象
                        document.body.removeChild(elink)
                    } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName)
                    }
                }
            })
    }
    function isJSON(str) {
        if (typeof str == 'string') {
            try {
                JSON.parse(str);
                return true;
            } catch(e) {
                console.log(e);
                return false;
            }
        }
        console.log('It is not a string!')
    }
    return (
        <>
            <Helmet>
                <title>{_t(t("Add New Item"))}</title>
            </Helmet>
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ManageSidebar />
                        </div>
                        {/* left Sidebar ends */}
                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
                            <div className="t-bg-white">
                                <div
                                    className="fk-scroll--pos-menu table-bottom-info-hide"
                                    data-simplebar
                                >
                                    <div className="t-pl-15 t-pr-15">
                                        <div key="smtp-form">
                                            <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                                                <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                                    <ul className="t-list fk-breadcrumb">
                                                        <li className="fk-breadcrumb__list">
                                                            <span className="t-link fk-breadcrumb__link text-capitalize">
                                                              {_t(t("Add new item"))}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 col-lg-7">
                                                    <div className="row gx-3 align-items-center"></div>
                                                </div>
                                            </div>

                                            <form
                                                className="row card p-2 mx-3 mb-5 sm-text"
                                                onSubmit={handleSubmit}

                                            >
                                                <div className="col-12">
                                                    <div className="form-group mt-4">
                                                        <div className="mb-2">
                                                            <label
                                                                htmlFor="image"
                                                                className="control-label"
                                                            >
                                                                {_t(t("Excel"))}
                                                                <span className="text-danger">*</span>{" "}
                                                                <small className="text-secondary">
                                                                    ({_t(t("Excel File"))})
                                                                </small>
                                                                <a href={BASE_URL+"/settings/new-food-excel-model"} target="_blank">Download template</a>
                                                            </label>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                type="file"
                                                                className="form-control sm-text"
                                                                id="excel"
                                                                name="excel"
                                                                onChange={setNewFile}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group mt-5 pb-2">
                                                        <div className="col-lg-12">
                                                            <button
                                                                className="btn btn-primary px-5"
                                                                type="submit"
                                                            >
                                                                {_t(t("Add"))}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default ExcelItem;
